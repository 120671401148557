<template>
  <div class="shopadmin-login-bg-container">
    <slot></slot>
    <div class="copyright">Copyright @ 2020 松鼠速客<span style="margin:0 10px 0 7px;">|</span>沪ICP备2021000952号-1</div>
  </div>
</template>

<script>
import { $sg_checkShell } from '@/api/login';

export default {
  name: 'ShopPcloginBg',
  data() {
    return {
      // shoDownUrl: false,
    };
  },
  props: {
    shoDownUrl: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    checkShell() {
      $sg_checkShell().then((is) => {
        this.shoDownUrl = !is;
      });
    },
  },
  created() {
    // this.checkShell();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/style/var.scss";
.shopadmin-login-bg-container {
  min-height: 100vh;
  background-color: #fafafa;
  background-size: 100% 100%;
  box-sizing: border-box;
  .down-text {
    width: 100%;
    position: fixed;
    bottom: 50px;
    text-align: center;
  }
  .title {
    margin: 0;
    background-color: rgba(255, 255, 255, 0.95);
    // color: #fff;
    // height: 80px;
    & > div {
      width: 1100px;
      margin: 0 auto;
      height: 80px;
      line-height: 80px;
      img {
        vertical-align: middle;
      }
    }
  }
  .login-form {
    background: #fff;
    width: 100%;
    height:620px;
    margin: 9px 0;
    padding: 53px 90px 53px 77px;
    box-sizing: border-box;
    &.login-findPassword-main {
      // .el-form-item:first-of-type{
      //   margin-bottom:0 !important;
      // }
      .login-input {
        input {
          height: auto !important;
        }
      }
    }
    .login-tit {
      padding-bottom: 0px;
      h5 {
        font-size: 20px;
        margin: 0;
        line-height: 1;
        font-weight: bold;
        &.login-tit-text {
          .login-title {
            padding: 36px 0 14px 0;
          }
          // &:first-child {
          //   margin-bottom: 16px;
          // }
        }
      }
      color: #444;
    }
    .is-success .el-input__inner {
      border-color: $--theme-color;
    }
    .login-input {
      .el-input__prefix {
        display: flex;
        align-items: center;
      }
      .s-pc-iconfont {
        font-size: 20px;
        color: #333333;
        margin-left: 6px;
      }
      .line {
        display: inline-block;
        width: 1px;
        height: 20px;
        margin-left: 12px;
        background-color: #999999;
      }
      input {
        // width: 345px;
        font-size: 14px;
        margin: 0 2.5px;
        height: 50px;
        padding: 0 0 0 40px;
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
      }
    }
    .el-checkbox__label,
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #aaaaaa;
    }
    .login-code {
      input {
        width: 254px;
      }
    }
    .login-phone-code {
      position: relative;
      .login-send {
        position: absolute;
        top: 15px;
        right: -3px;
        width: 90px;
        height: 32px;
        padding: 0;
        color: #aaaaaa;
        font-size: 14px;
        font-weight: 400;
        &.login-send-other {
          top: 6px;
        }
      }
    }

    .login-img-code {
      position: relative;
      img.verify_code {
        width: 72px;
        height: 32px;
        position: absolute;
        right: -3px;
        top: 15px;
        border-radius: 4px;
        &.verify_code-other {
          top: 6px;
        }
      }
    }
    .login-btn {
      .el-button {
        width: 100%;
        height: 50px;
        // border-radius: 100px;
        font-size: 18px;
        color: white;
        border: none;
        font-weight: 400;
        opacity: 1;
        background: $--theme-color;
        // &.allow-login:hover{
        // background-color: #0DC7D1;
        // box-shadow:10px 0 20px rgba(19,162,175,0.4);
        // }
      }
      .back-login {
        text-align: left;
        color: $--theme-color;
      }
    }
    .allow-login {
      // background-color: $--theme-color;
      background: $--background-linear-gradient-color;
    }
    .refuse-login {
      background-color: #bbbfbf;
    }
    .to-regist {
      a {
        color: $--theme-color;
      }
    }
  }
  .down-link {
    color: #ffcb47;
    text-decoration: underline;
  }
  .login-container {
    position: absolute;
    top: calc(50% - 75px);
    left: 50%;
    margin-left: -689.5px;
    margin-top: -319px;
    width: 1379px;
    height: 638px;
    overflow: hidden;
    box-sizing: border-box;
    background-image: url("/img/login/login-people.png");
    background-repeat: no-repeat;
    background-size: 783px 638px;
    padding-left: 859px;
    .login-tooltip {
      position: absolute;
      top: 22px;
      right: 90px;
      .tooltip {
        display: inline-block;
        background-color: rgba(218, 244, 246, 1);
        color: $--theme-color;
        border: 1px solid $--theme-color;
        padding: 5px 8px;
        white-space: nowrap;
        position: absolute;
        right: 100%;
        top: 10px;
        margin-right: 6px;
        &:before {
          content: "";
          display: block;
          position: absolute;
          right: -7px;
          top: 9px;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-left: 6px solid $--theme-color;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          right: -6px;
          top: 9px;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-left: 6px solid rgba(218, 244, 246, 1);
        }
      }
    }
    .login-logo {
      width: 78px;
      height: 78px;
    }
  }
  .copyright {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #999999;
    position: absolute;
    bottom: 55px;
    left:50%;
    margin-left: -182.5px;
    overflow: hidden;
  }
}

// 移动端屏幕的兼容
.view-mobile-page .shopadmin-login-bg-container .login-container {
  background-image: none;
  padding-left: 0;
  margin-left: 0;
  margin-top: 0;
  position: unset;
  width: 100%;
  .login-form {
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
}
</style>
